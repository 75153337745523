import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

function DuaTop() {
    const [users, setUsers] = useState([]);
    const [timeLeft, setTimeLeft] = useState(0);
    const [currentDay, setCurrentDay] = useState(new Date().getDate());

    // Set the update interval to 1 hour (in milliseconds)
    const updateInterval = 1 * 60 * 60 * 1000;

    useEffect(() => {
        fetchLeaderboard();

        const timer = setInterval(() => {
            const now = new Date();
            const dayEndTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime();
            const remainingTime = Math.max(0, dayEndTime - now.getTime());

            setTimeLeft(Math.floor(remainingTime / 1000));
        }, 1000);

        // Set up interval for data fetching every 1 hour
        const fetchInterval = setInterval(fetchLeaderboard, updateInterval);

        return () => {
            clearInterval(timer);
            clearInterval(fetchInterval);
        };
    }, []);

    const fetchLeaderboard = async () => {
        try {
            const response = await fetch('/api/leaderboard');
            const data = await response.json();
            setUsers(data.leaderboardData);
            setCurrentDay(data.currentDay);
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    const formatTime = (seconds) => {
        const hours = Math.floor((seconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h ${minutes}m ${secs}s`;
    };

    return (
        <Container>
            <div className="row mb-3">
                <div className="col">
                    <div className="card bg-secondary">
                        <div className="card-header border-0 bg-label-danger mb-3">
                            <div className="card-title mb-0 d-flex justify-content-between">
                                <div>
                                    <h4 className="mb-0">Top User</h4> <span>Cập nhật hàng ngày</span></div>
                                <div className='me-3'><span>Kết thúc sau</span>
                                    <h6 className="m-0">{formatTime(timeLeft)}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="mb-4"><h2 className=" me-3" type="button">Nhiệm vụ</h2></div>
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive mb-2" style={{ height: "375px" }}>
                                            <table className="table align-items-center text-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="p-0 pe-2 text-center">#</th>
                                                        <th className="p-0 ps-2 text-start">Tên user</th>
                                                        <th className="p-0 pe-2 text-start">Tổng số nhiệm vụ</th>
                                                        <th className="p-0 pe-2 text-start">Giải thưởng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.map((user, index) => (
                                                        <tr key={user._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{user.name}<br /><small className="text-muted">{user.email}</small></td>
                                                            <td>{user.totalTasks}</td>
                                                            <td>{user.reward}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default DuaTop;