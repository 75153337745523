import { useEffect } from 'react';

const ScriptLoader = ({ src, async = true, defer = true }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = async;
        script.defer = defer;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src, async, defer]);

    return null;
};

export default ScriptLoader;