import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Function to check if a specific cookie is set
    const checkLoginStatus = () => {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            if (cookie.trim().startsWith('auth_token=')) {
                return true;
            }
        }
        return false;
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // Check login status when the component mounts
        if (checkLoginStatus()) {
            setIsLoggedIn(true);
            navigate('/member'); // Redirect to /member if logged in
        }
    }, [navigate]);

    const googleLogin = useGoogleLogin({
        ux_mode: 'redirect',
        redirect_uri: 'https://moneytask.top/login/google/callback',
        flow: 'auth-code',
        onSuccess: async ({ code }) => {
            try {
                await axios.post('/api/auth/google', { code });
            } catch (error) {
                console.error('Error during Google login:', error);
            }
        },
        onError: errorResponse => console.log(errorResponse),
    });

    return (
        <>
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex justify-content-between align-items-center">

                    <div className="logo">
                        <h1 className="text-light"><a href="/"><img src={`${process.env.PUBLIC_URL}/assets/img/logo-nobg.png`} alt="" /> <span>Money Task</span></a></h1>
                    </div>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a href="#contact">Liên hệ</a></li>
                            <li><a href="/login">Đăng nhập</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" />
                    </nav>

                </div>
            </header>

            <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="card mb-4 login-form">
                            {/* <!-- <img src="assets/img/logo-nobg.png" className="card-img" alt="logo" style="opacity: 0.5;"> --> */}
                            <div className="card-bg-info mt-3">
                                <h3 className="card-title">Chào mừng tới Money Task</h3>
                                <h5 className="card-title">Nền tảng kiếm tiền số 1 Việt Nam!</h5>
                                <p className="card-text">Đăng nhập tài khoản ngay nào!</p>

                                <div className="g-4 my-5">
                                    <div className="row px-xl-5 px-sm-4 px-3 justify-content-center mb-3">
                                        <div className="col-12 px-1 cursor-pointer">
                                            <button className="btn btn-warning w-100 btn-lg" style={{ color: "rgb(34, 34, 34)", backgroundcolor: "rgb(254,194,16)", background: "linear-gradient(270deg, rgba(254,194,16,1) 0%, rgba(255,200,34,1) 35%, rgba(255,205,54,1) 100%)" }} onClick={() => googleLogin()} type="button"> <i className="fab fa-google" /> Đăng nhập bằng Google </button>
                                        </div>
                                    </div>
                                </div>

                                <p id="text-center mt-5 card-text">Tham gia cộng đồng chúng tôi ngay hôm nay</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
