import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Tabs, Tab, Table } from 'react-bootstrap';

function DuyetNhiemVu() {
    const [acpTasks, setAcpTasks] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('newest'); // Add state for sorting order
    const tasksPerPage = 30;

    useEffect(() => {
        axios.get('/api/am/taskac', { withCredentials: true })
            .then((usersResponse) => {
                if (usersResponse.status === 200) {
                    setAcpTasks(usersResponse.data);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleAcceptTask = async (taskId, action) => {
        axios.post('/api/am/taskac', { taskId, action }, { withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    console.log('Task accepted:', response.data);
                    setAcpTasks(prevTasks =>
                        prevTasks.map(task =>
                            task._id === taskId ? { ...task, com: action === 'accept' ? 1 : 2 } : task
                        )
                    );
                }
            })
            .catch(error => console.error('Error accepting task:', error));
    };

    const yeumoneyTasks = acpTasks?.filter(task => task.url.includes("yeumoney")) || [];
    const linkTasks = acpTasks?.filter(task => task.url.includes("8link")) || [];

    const renderTasks = (tasks) => {
        const sortedTasks = tasks.sort((a, b) => {
            if (sortOrder === 'newest') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            return new Date(a.createdAt) - new Date(b.createdAt);
        });

        const indexOfLastTask = currentPage * tasksPerPage;
        const indexOfFirstTask = indexOfLastTask - tasksPerPage;
        const currentTasks = sortedTasks.slice(indexOfFirstTask, indexOfLastTask);

        return (
            <>
                {currentTasks.map((task) => (
                    <tr key={task._id}>
                        <td>{task.url.includes("yeumoney") ? "Yeumoney" : "8link io"}</td>
                        <td>{`${new Date(task.createdAt).toLocaleString()}`}</td>
                        <td>
                            {
                                task.com === 0 ? (
                                    <>
                                        <Button variant="danger" onClick={() => handleAcceptTask(task._id, 'reject')}>Từ chối</Button>
                                        <Button variant="success" onClick={() => handleAcceptTask(task._id, 'accept')}>Chấp nhận</Button>
                                    </>
                                ) : task.com === 1 ? (
                                    "Đã duyệt"
                                ) : task.com === 2 ? (
                                    "Từ chối"
                                ) : null
                            }
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="3">
                        <Button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>Trang đầu</Button>
                        <Button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Trang trước</Button>
                        <span className='mx-lg-2'> Trang {currentPage}/{Math.ceil(tasks.length / tasksPerPage)} </span>
                        <Button onClick={() => setCurrentPage(prev => prev + 1)} disabled={indexOfLastTask >= tasks.length}>Trang sau</Button>
                        <Button onClick={() => setCurrentPage(Math.ceil(tasks.length / tasksPerPage))} disabled={indexOfLastTask >= tasks.length}>Trang cuối</Button>
                    </td>
                </tr>
            </>
        );
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y overflow-x-hidden">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="row">
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số nhiệm vụ Yeumoney</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> {yeumoneyTasks.length} </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-danger rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số nhiệm vụ 8link </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> {linkTasks.length} </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-info rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between mb-3">
                                <h5>Sắp xếp nhiệm vụ:</h5>
                                <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                                    <option value="newest">Mới nhất tới cũ nhất</option>
                                    <option value="oldest">Cũ nhất tới mới nhất</option>
                                </select>
                            </div>
                            <Tabs defaultActiveKey="yeumoney" id="task-tabs">
                                <Tab eventKey="yeumoney" title="Yeumoney">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Nhiệm vụ</th>
                                                <th>Thời gian</th>
                                                <th>Duyệt nhiệm vụ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTasks(yeumoneyTasks)}
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab eventKey="link" title="8link">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Nhiệm vụ</th>
                                                <th>Thời gian</th>
                                                <th>Duyệt nhiệm vụ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTasks(linkTasks)}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DuyetNhiemVu;