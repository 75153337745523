import ScriptLoader from "../useScripts";

function NotFound() {
    return (
        <>
            <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                                    <span className="display-1 fw-bold">4</span>
                                    <i className="bi bi-exclamation-circle-fill text-danger display-4" />
                                    <span className="display-1 fw-bold bsb-flip-h">4</span>
                                </h2>
                                <h3 className="h2 mb-2">Nhiệm vụ hết mã</h3>
                                <p className="mb-5">Hiện tại nhiệm vụ đang hết mã, vui lòng thử lại sau</p>
                                <a className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" href="/member" role="button">Trở về trang chủ</a>
                            </div>
                        </div>
                        <div className="col-12">
                            <ScriptLoader src="https://www.vipads.live/vn/c-2067-25.js"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;