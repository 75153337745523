import React from 'react';
import { Link } from 'react-router-dom';

function Index() {

    return (
        <>
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <div className="container d-flex justify-content-between align-items-center">

                    <div className="logo">
                        <h1><Link to="/"><img src="assets/img/logo-nobg.png" alt="" /> <span className="text-dark">Money Task</span></Link></h1>
                    </div>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a href="#contact" className='text-dark'>Liên hệ</a></li>
                            <li><Link to="login" className='text-dark'>Đăng nhập</Link></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" />
                    </nav>

                </div>
            </header>

            <section id="hero" className="d-flex justify-cntent-center align-items-center">
                <div id="heroCarousel" className="container carousel carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active">
                        <div className="carousel-container">
                            <h2 className="animate__animated animate__fadeInDown">Money Task</h2>
                            <p className="animate__animated animate__fadeInUp">Nền tảng kiếm tiền <span className="typed" data-typed-items="uy tín, ổn định, rate cao" /> số 1 Việt Nam</p>
                            <Link to="login" className="btn-get-started animate__animated animate__fadeInUp">Tham gia ngay!</Link>
                        </div>
                    </div>
                </div>
            </section>

            <main id="main">

                {/* <!-- ======= Features Section ======= --> */}
                <section className="features">
                    <div className="container">

                        <div className="section-title ">
                            <h2>CÁC NỀN TẢNG</h2>
                        </div>

                        <div className="row" data-aos="fade-up">
                            <div className="col-md-5">
                                <img src="assets/img/link.svg" className="img-fluid" alt="" style={{ width: '451px', height: '256.75px' }} />
                            </div>
                            <div className="col-md-7 pt-4 ">
                                <h2>VƯỢT LINK RÚT GỌN</h2>
                                <h4>
                                    Hiện nay, hệ thống đang có hơn 100 nhiệm vụ đang chờ bạn hoàn thành. <br /> Nếu chăm chỉ bạn có thể kiếm được hơn 20.000 VNĐ/ngày.
                                </h4>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">
                            <div className="col-md-5 order-1 order-md-2">
                                <img src="assets/img/map.svg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-7 pt-5 order-2 order-md-1 ">
                                <h2>ĐÁNH GIÁ MAP</h2>
                                <h4> Hiện nay, hệ thống đang có hơn 100 nhiệm vụ đang chờ bạn hoàn thành. <br /> Nếu chăm chỉ bạn có thể kiếm được hơn 70.000 VNĐ/ngày. </h4>
                            </div>
                        </div>

                        <div className="row" data-aos="fade-up">
                            <div className="col-md-5">
                                <img src="assets/img/app.svg" className="img-fluid" alt="" style={{ width: '451px', height: '256.75px' }} />
                            </div>
                            <div className="col-md-7 pt-5 ">
                                <h2>ĐÁNH GIÁ APP</h2>
                                <h4>Hiện nay, hệ thống đang có hơn 100 nhiệm vụ đang chờ bạn hoàn thành. <br /> Nếu chăm chỉ bạn có thể kiếm được hơn 70.000 VNĐ/ngày.</h4>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="payout">
                    <div className="container">

                        <div className="section-title ">
                            <h2>RÚT TIỀN</h2>
                            <p>Rút tiền rất đơn giản. Chúng tôi hỗ trợ một số phương thức thanh toán, tùy thuộc vào tài khoản thanh toán của bạn đăng ký.</p>
                        </div>

                        <div className="row">
                            <div className="col-md col-lg d-flex align-items-stretch" data-aos="fade-up">
                                <div className="icon-box icon-box-pink">
                                    <img style={{ border: '0', borderRadius: '20px', boxShadow: '0 0 8px #000', marginBottom: '15px' }} width='250px' height='250px' src="assets/img/momo.webp" alt="momo" />
                                    <h4 className="title"><a href="/">Rút tiền qua Momo</a></h4>
                                </div>
                            </div>

                            <div className="col-md col-lg d-flex align-items-stretch" data-aos="fade-up">
                                <div className="icon-box icon-box-pink">
                                    <img style={{ border: '0', borderRadius: '20px', boxShadow: '0 0 8px #000', marginBottom: '15px' }} width='250px' height='250px' src="assets/img/bank.webp" alt="momo" />
                                    <h4 className="title"><a href="/">Rút tiền qua Ngân hàng</a></h4>
                                </div>
                            </div>

                            <div className="col-md col-lg d-flex align-items-stretch" data-aos="fade-up">
                                <div className="icon-box icon-box-pink">
                                    <img style={{ border: '0', borderRadius: '20px', boxShadow: '0 0 8px #000', marginBottom: '15px' }} width='250px' height='250px' src="assets/img/card.webp" alt="momo" />
                                    <h4 className="title"><a href="/">Rút tiền qua Thẻ điện thoại</a></h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title ">
                            <h2>Liên hệ</h2>
                        </div>
                        <div className="row mt-1 ms-5 ">
                            <div className="row info">
                                <div className="col-md col-lg align-items-stretch ms-5 text-center">
                                    <h4><i className="fab fa-telegram" /> Telegram:</h4>
                                    <p><a href="https://t.me/namkhanh01" target="_blank" rel="noreferrer">Nam Khánh</a></p>
                                </div>
                                <div className="col-md col-lg align-items-stretch ms-5 text-center">
                                    <h4><i className="fas fa-circle-z" /> Zalo:</h4>
                                    <p><a href='https://zalo.me/0944036100' target="_blank" rel="noreferrer">0944036100</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

            {/* <!-- ======= Footer ======= --> */}
            <a href="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        </>
    );
}

export default Index;