import React, { useEffect, useState } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';

function QuanLyTaiKhoan() {
    const [users, setUsers] = useState(null);
    const [showModal, setShowModal] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(20); // Updated to 20 users per page
    const [searchQuery, setSearchQuery] = useState('');

    const [cash, setCash] = useState({ choDuyet: 0, daDuyet: 0 });

    const [duplicateIPUsers, setDuplicateIPUsers] = useState([]);
    const [currentIPPage, setCurrentIPPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [searchQueryIP, setSearchQueryIP] = useState('');

    // Function to handle showing the modal for a specific user
    const handleShow = (id) => {
        const user = users.find(user => user._id === id);
        // Update the cash state with the selected user's cash values
        setCash({ choDuyet: user.cash.choDuyet, daDuyet: user.cash.daDuyet });
        setShowModal({ ...showModal, [id]: true });
    };

    // Function to handle closing the modal for a specific user
    const handleClose = (id) => {
        setShowModal({ ...showModal, [id]: false });
        // Optionally reset the cash state when the modal is closed
        setCash({ choDuyet: 0, daDuyet: 0 });
    };

    useEffect(() => {
        fetchUsers();
        fetchDuplicateIPUsers();
    }, []);

    const fetchUsers = () => {
        axios.get('/api/am/users', { withCredentials: true })
            .then((usersResponse) => {
                if (usersResponse.status === 200) {
                    setUsers(usersResponse.data);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchDuplicateIPUsers = () => {
        axios.get('/api/am/ipcheck', { withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    setDuplicateIPUsers(response.data);
                }
            })
            .catch(error => console.error('Error fetching duplicate IP users:', error));
    };

    const handleToggleAcc = async (userId) => {
        axios.post('/api/am/users', { userId }, { withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    console.log('User locked:', response.data);
                    fetchUsers(); // Re-fetch users to update the state
                }
            })
            .catch(error => console.error('Error locking user:', error));
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const handleSearchIP = (event) => {
        setSearchQueryIP(event.target.value);
        setCurrentIPPage(1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCash({
            ...cash,
            [name]: value
        });
    };

    const handleSubmit = (userId) => {
        axios.post('/api/am/cashedit', { userId, cash }, { withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    console.log('Cash updated:', response.data);
                    fetchUsers(); // Re-fetch users to update the state
                    handleClose(userId);
                }
            })
            .catch(error => console.error('Error updating cash:', error));
    };

    const filteredUsers = users ? users.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase())) : [];
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Pagination logic for IP users
    const filteredUsersIP = users ? users.filter(user => user.email.toLowerCase().includes(searchQueryIP.toLowerCase())) : [];
    const indexOfLastIPItem = currentIPPage * itemsPerPage;
    const indexOfFirstIPItem = indexOfLastIPItem - itemsPerPage;
    const currentIPItems = duplicateIPUsers.slice(indexOfFirstIPItem, indexOfLastIPItem);

    const paginateIP = (pageNumber) => setCurrentIPPage(pageNumber);

    const colors = ['#f8f9fa', '#d4d7d9']; // Define colors for striping

    return (
        <>
            <div className="row mb-3 ms-lg-5 ps-lg-5">
                <div className="col-md-10 ms-lg-5 ps-lg-5">
                    <div className="card bg-secondary">
                        <div className="card-header border-0 bg-label-danger mb-3">
                            <div className="card-title mb-0 d-flex justify-content-between">
                                <div>
                                    <h4 className="mb-0">Quản lý tài khoản thành viên</h4>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm theo email"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive mb-2" style={{ height: "375px" }}>
                                        <table className="table align-items-center table-striped text-sm">
                                            <thead>
                                                <tr>
                                                    <th className="p-0 pe-2 text-truncate">#</th>
                                                    <th className="p-0 ps-2 text-truncate">Tên</th>
                                                    <th className="p-0 pe-2 text-truncate">Email</th>
                                                    <th className="p-0 pe-2 text-truncate">Số dư chờ duyệt</th>
                                                    <th className="p-0 pe-2 text-truncate">Số dư đã duyệt</th>
                                                    <th className="p-0 pe-2 text-truncate">Hành động</th>
                                                </tr>
                                            </thead>
                                            {currentUsers && (currentUsers.filter(users => users.role !== 'admin').map((users, index) => (
                                                <tbody className='mt-lg-2' key={users._id}>
                                                    <tr>
                                                        <td className="p-0 pe-2 text-truncate">{index + 1 + indexOfFirstUser}</td>
                                                        <td className="p-0 ps-2 text-truncate">{users.name}</td>
                                                        <td className="p-0 pe-2 text-truncate">{users.email}</td>
                                                        <td className="p-0 pe-2 text-truncate">{users.cash.choDuyet}</td>
                                                        <td className="p-0 pe-2 text-truncate">{users.cash.daDuyet}</td>
                                                        <td className="p-0 pe-2 text-truncate">
                                                            <button className={users.locked ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} type="button" onClick={() => handleToggleAcc(users._id)} id={users._id}> {users.locked ? "Mở Khoá" : "Khoá Acc"} </button>
                                                            <Button className="btn btn-sm btn-primary ms-lg-1" type="button" onClick={() => handleShow(users._id)} id={users._id}> Kiểm tra thông tin</Button>
                                                        </td>
                                                    </tr>
                                                    <Modal className="modal fade" id={`userInfo_${users._id}`} tabIndex="-1" show={showModal[users._id] || false} onHide={() => handleClose(users._id)} >
                                                        <Modal.Header className="modal-header">
                                                            <h1 className="modal-title fs-5" id={`userInfo_${users._id}`}>Kiểm tra thông tin thành viên {users.email}</h1>
                                                        </Modal.Header>
                                                        <Modal.Body className="modal-body overflow-y-auto">
                                                            <Tabs defaultActiveKey="userLog" id="user-info-tabs">
                                                                <Tab eventKey="userLog" title="User Log">
                                                                    <div className='overflow-auto'>
                                                                        <table className="table table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col" className="text-truncate">Địa chỉ IP</th>
                                                                                    <th scope="col" className="text-truncate">User-Agent</th>
                                                                                    <th scope="col" className="text-truncate">Thời gian đăng nhập</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="table-group-divider">
                                                                                {users && (users.userLog.map((log, index) => (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td className="text-truncate">{log.ipAdd}</td>
                                                                                        <td className="text-truncate">{log.userAgent}</td>
                                                                                        <td className="text-truncate">{new Date(log.time).toLocaleString()}</td>
                                                                                    </tr>
                                                                                )))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="cash" title="Cash">
                                                                    <div className="form-group">
                                                                        <label htmlFor="choDuyet">Cash chờ duyệt</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="choDuyet"
                                                                            name="choDuyet"
                                                                            value={cash.choDuyet}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="daDuyet">Cash đã duyệt</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="daDuyet"
                                                                            name="daDuyet"
                                                                            value={cash.daDuyet}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <Button type="button" className="btn btn-primary mt-3" onClick={() => handleSubmit(users._id)}>Xác nhận</Button>
                                                                </Tab>
                                                            </Tabs>
                                                            <Button type="reset" className="btn btn-secondary mt-3" onClick={() => handleClose(users._id)}>Đóng</Button>
                                                        </Modal.Body>
                                                    </Modal>
                                                </tbody>
                                            )))}
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}> {'<'} </Button>
                                        <span className="mx-lg-2">Trang {currentPage} / {Math.ceil(filteredUsers.length / usersPerPage)}</span>
                                        <Button onClick={() => paginate(currentPage + 1)} disabled={indexOfLastUser >= filteredUsers.length}> {'>'} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card bg-secondary mt-3">
                        <div className="card-header border-0 bg-label-danger mb-3">
                            <div className="card-title mb-0 d-flex justify-content-between">
                                <div>
                                    <h4 className="mb-0">Tài khoản trùng IP</h4>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Tìm kiếm theo email"
                                        value={searchQueryIP}
                                        onChange={handleSearchIP}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive mb-2" style={{ height: "375px" }}>
                                        <table className="table align-items-center table-hover text-sm">
                                            <thead>
                                                <tr>
                                                    <th className="p-0 pe-2 text-truncate">#</th>
                                                    <th className="p-0 ps-2 text-truncate">Tên</th>
                                                    <th className="p-0 pe-2 text-truncate">Email</th>
                                                    <th className="p-0 pe-2 text-truncate">Số dư</th>
                                                    <th className="p-0 pe-2 text-truncate">Địa chỉ IP</th>
                                                    <th className="p-0 pe-2 text-truncate">Hành động</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {duplicateIPUsers.map((group, groupIndex) => (
                                                    <React.Fragment key={group.ip}>
                                                        {group.users.map((user, userIndex) => (
                                                            <tr key={user._id} style={{ backgroundColor: colors[groupIndex % colors.length] }}>
                                                                <td className="p-0 pe-2 text-truncate">{groupIndex + 1}.{userIndex + 1}</td>
                                                                <td className="p-0 ps-2 text-truncate">{user.name}</td>
                                                                <td className="p-0 pe-2 text-truncate">{user.email}</td>
                                                                <td className="p-0 pe-2 text-truncate">{user.cash}</td>
                                                                {userIndex === 0 && (
                                                                    <td className="p-0 pe-2 text-truncate" rowSpan={group.users.length}>
                                                                        {group.ip}
                                                                    </td>
                                                                )}
                                                                <td className="p-0 pe-2 text-truncate">
                                                                    <button className={user.locked ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} type="button" onClick={() => handleToggleAcc(user._id)} id={user._id}>
                                                                        {user.locked ? "Mở Khoá" : "Khoá Acc"}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuanLyTaiKhoan;