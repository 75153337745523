import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Index from './App';
import Login from './Login';
import MemberRoutes from "./member/MemberRoutes";
import ThongBao from "./member/ThongBao";
import NhiemVu from "./member/NhiemVu";
import RutTien from "./member/RutTien";
import DuaTop from "./member/DuaTop";
import GioiThieu from "./member/GioiThieu";
import QuyDinh from "./member/QuyDinh";
import NotFound from "./member/NotFound";

import { DataProvider } from './GetData';

import HoSo from "./hoso/HoSo";
// import ThanhToan from "./hoso/ThanhToan";
// import DangNhap from "./hoso/DangNhap";


import TongQuan from './admin/TongQuan';
import QuanLyNhiemVu from './admin/QLNhiemVu';
import QuanLyTaiKhoan from './admin/QLTaiKhoan';
import QuanLySoDu from './admin/QLSoDu';
import DuyetNhiemVu from './admin/DuyetNhiemVu';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="421310930439-notbrn6dpv8gu6r06okmj4297s22ur36.apps.googleusercontent.com">
        <React.StrictMode>
            <BrowserRouter>
                <DataProvider>
                    <Routes>
                        <Route path="/" element={<Index />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/member" element={<MemberRoutes />}>
                            <Route index element={<ThongBao />} />
                            <Route path="/member/hoso" element={<HoSo />} />
                            <Route path="/member/nhiemvu" element={<NhiemVu />} />
                            <Route path="/member/ruttien" element={<RutTien />} />
                            <Route path="/member/duatop" element={<DuaTop />} />
                            <Route path="/member/gioithieu" element={<GioiThieu />} />
                            <Route path="/member/quydinh" element={<QuyDinh />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="/admin" element={<MemberRoutes />}>
                            <Route index element={<TongQuan />} />
                            <Route path="/admin/tasks" element={<QuanLyNhiemVu />} />
                            <Route path="/admin/accounts" element={<QuanLyTaiKhoan />} />
                            <Route path="/admin/balances" element={<QuanLySoDu />} />
                            <Route path="/admin/tasksaccept" element={<DuyetNhiemVu />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </DataProvider>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);
