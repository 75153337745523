import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import { useData } from '../GetData';

function QuanLyNhiemVu() {
    const { tasks } = useData();

    // MODAL BOX
    const [showModal, setShowModal] = useState({});
    const handleShow = (id) => setShowModal({ ...showModal, [id]: true });
    const handleClose = (id) => setShowModal({ ...showModal, [id]: false });;

    const [addTaskModal, setaddTaskModal] = useState(false);
    const handleAddTaskClose = () => setaddTaskModal(false);
    const handleAddTaskShow = () => setaddTaskModal(true);

    const handleAddTasks = (event) => {
        event.preventDefault();
        const formData = {
            taskName: document.getElementById('taskName').value,
            apiURL: document.getElementById('apiURL').value,
            taskPrice: document.getElementById('taskPrice').value,
            taskViews: document.getElementById('taskViews').value,
            taskDesc: document.getElementById('taskDesc').value,
            taskMinTime: document.getElementById('taskMinTime').value,
        };
        axios
            .post('/api/am/addtask', formData, { withCredentials: true })
            .then((res) => { if (res.status === 200) { window.location.reload() } })
            .catch(err => {
                console.error(err);
            });
    };
    const handleEditTasks = (event, taskId) => {
        event.preventDefault();
        const formData = {
            taskName: document.getElementById(`taskName_${taskId}`).value,
            apiURL: document.getElementById(`apiURL_${taskId}`).value,
            taskPrice: document.getElementById(`taskPrice_${taskId}`).value,
            taskViews: document.getElementById(`taskViews_${taskId}`).value,
            taskDesc: document.getElementById(`taskDesc_${taskId}`).value,
            taskMinTime: document.getElementById(`taskMinTime_${taskId}`).value,
        };
        axios.post('/api/am/edittask', { formData, taskId }, { withCredentials: true })
            .then((res) => { if (res.status === 200) { window.location.reload() } })
    };
    const handleToggleTasks = (taskId) => {
        // console.log(taskId)
        axios.post('/api/am/toggletask', { taskId: taskId }, { withCredentials: true })
        // .then((res) => res.status === 200 ? console.log(res.data) : console.error(res))
    }
    const handleDelTasks = (taskId) => {
        // console.log(taskId)
        axios.post('/api/am/deltask', { taskId: taskId }, { withCredentials: true })
            .then((res) => res.status === 200 ? window.location.reload() : console.error(res))
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y overflow-x-hidden">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="row">
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số nhiệm vụ </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> {tasks?.length} </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-danger rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số lượt làm </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> 0 </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-info rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Số lượt làm còn lại </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> 0 (đã làm 0) </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-warning rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-3">
                        <div className="col-md col-sm-6">
                            <Button className="btn btn-sm btn-primary mb-0" style={{ borderRadius: "10px" }} onClick={handleAddTaskShow}> <span>Thêm nhiệm vụ</span> </Button>
                        </div>
                        <Modal className="modal fade" id="addTasks" tabIndex="-1" show={addTaskModal} onHide={handleAddTaskClose}>
                            <Modal.Header closeButton>
                                <h1 className="modal-title fs-5" id="addTasks">Thêm nhiệm vụ mới</h1>
                            </Modal.Header>
                            <Modal.Body className="modal-body">
                                <form onSubmit={handleAddTasks}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="taskName">Tên nhiệm vụ</label>
                                        <input type="text" className="form-control" id="taskName" name="taskName" placeholder="Tên nhiệm vụ" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="apiURL">URL API</label>
                                        <input type="text" className="form-control" id="apiURL" name="apiURL" placeholder="URL API Nhiệm vụ" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="taskPrice">Số tiền</label>
                                        <input type="number" className="form-control" id="taskPrice" name="taskPrice" placeholder="Số tiền của nhiệm vụ" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="taskViews">Số lượt</label>
                                        <input type="number" className="form-control" id="taskViews" name="taskViews" placeholder="Số lượt làm nhiệm vụ" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="taskDesc">Nội dung nhiệm vụ</label>
                                        <input type="text" className="form-control" id="taskDesc" name="taskDesc" placeholder="Nội dung nhiệm vụ" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="taskMinTime">Thời gian tối thiếu để hoàn thành nhiệm vụ (Tính bằng giây)</label>
                                        <input type="number" className="form-control" id="taskMinTime" name="taskMinTime" placeholder="Thời gian tối thiểu" />
                                    </div>
                                    <Button type="button" className="btn btn-secondary" onClick={handleAddTaskClose}>Huỷ</Button>
                                    <button type="submit" className="btn btn-primary ms-2">Thêm nhiệm vụ</button>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className="row">
                        {tasks && (tasks.sort((a, b) => b.taskPrice - a.taskPrice).map((task) => (
                            <div className="col-xl-3 col-sm-6" key={task._id}>
                                <div className="mb-4">
                                    <div className="card bg-secondary">
                                        <div className="card-body p-3">
                                            <div className="d-flex">
                                                <div className="my-auto w-100">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <h6 className="" id={`taskName1_${task._id}`}> {task.taskName} </h6>
                                                    </div>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" aria-checked={task.enable} defaultChecked={task.enable} onChange={() => handleToggleTasks(task._id)} />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Bật/tắt nhiệm vụ</label>
                                                    </div>
                                                    {/* Modal */}
                                                    <Button className="btn btn-sm btn-primary mb-0" style={{ borderRadius: "10px" }} type="button" onClick={() => handleShow(task._id)}> <span>Chỉnh sửa nhiệm vụ</span>
                                                    </Button>
                                                    <button className="btn btn-sm btn-danger mt-2 mb-0" style={{ borderRadius: "10px" }} type="button" onClick={() => handleDelTasks(task._id)}> <span>Xoá nhiệm vụ</span>
                                                    </button>
                                                    <Modal className="modal fade" id={`editTask_${task._id}`} tabIndex="-1" show={showModal[task._id] || false} onHide={() => handleClose(task._id)} >
                                                        <Modal.Header className="modal-header">
                                                            <h1 className="modal-title fs-5" id={`editTask_${task._id}`}>Chỉnh sửa nhiệm vụ</h1>
                                                        </Modal.Header>
                                                        <Modal.Body className="modal-body">
                                                            <form onSubmit={(e) => handleEditTasks(e, task._id)}>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`taskName_${task._id}`}>Tên nhiệm vụ</label>
                                                                    <input type="text" className="form-control" id={`taskName_${task._id}`} name="taskName" placeholder="Tên nhiệm vụ" defaultValue={task.taskName} />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`apiURL_${task._id}`}>URL API</label>
                                                                    <input type="text" className="form-control" id={`apiURL_${task._id}`} name="apiURL" placeholder="URL API Nhiệm vụ" defaultValue={task.taskUrl} />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`taskPrice_${task._id}`}>Số tiền</label>
                                                                    <input type="number" className="form-control" id={`taskPrice_${task._id}`} name="taskPrice" placeholder="Số tiền của nhiệm vụ" defaultValue={task.taskPrice} />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`taskViews_${task._id}`}>Số lượt</label>
                                                                    <input type="number" className="form-control" id={`taskViews_${task._id}`} name="taskViews" placeholder="Số lượt làm nhiệm vụ" defaultValue={task.taskViews} />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`taskDesc_${task._id}`}>Nội dung nhiệm vụ</label>
                                                                    <input type="text" className="form-control" id={`taskDesc_${task._id}`} name="taskDesc" placeholder="Nội dung nhiệm vụ" defaultValue={task.taskDesc} />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`taskMinTime_${task._id}`}>Thời gian tối thiểu để hoàn thành nhiệm vụ (tính bằng giây)</label>
                                                                    <input type="number" className="form-control" id={`taskMinTime_${task._id}`} name="taskMinTime" placeholder="Thời gian tối thiểu" defaultValue={task.taskMinTime} />
                                                                </div>
                                                                <Button type="reset" className="btn btn-secondary" onClick={() => handleClose(task._id)}>Huỷ</Button>
                                                                <button type="submit" className="btn btn-primary ms-2">Sửa nhiệm vụ</button>
                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>

                                                    {/* End Modal */}

                                                    <div className="avatar-group mt-3">
                                                        <div className="text-sm"> {task.taskDesc} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="horizontal light" />
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className="text-sm font-weight-bold mb-0 opacity-8"><i className="fas fa-gift" /> {task.taskPrice} </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm font-weight-bold mb-0 opacity-8"><i className="fas fa-hammer" /> {task.taskViews} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuanLyNhiemVu;
