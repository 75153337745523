import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { useData } from '../GetData';

function GioiThieu() {
    const { user } = useData();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);

    const handleCopy = () => {
        const input = document.getElementById("refLink");
        input.select();
        document.execCommand("copy");
        setLoading(true);
    };

    return (
        <Container className="text-dark">
            <Row className="mb-4 justify-content-md-center">
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>TỔNG NGƯỜI GIỚI THIỆU</Card.Title>
                            <Card.Text>{user?.referredUsers?.length}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                {/* <Col md={5}>
                    <Card>
                        <Card.Body>
                            <Card.Title>TỔNG NGƯỜI XÁC THỰC</Card.Title>
                            <Card.Text>0</Card.Text>
                        </Card.Body>
                    </Card>
                </Col> */}
                {/* <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>TỔNG TIỀN GIỚI THIỆU</Card.Title>
                            <Card.Text>0</Card.Text>
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>

            <Row className="mb-4">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Mời bạn bè để kiếm ngay nhận quà liền tay</Card.Title>
                            <Card.Text>
                                Bạn mời bạn giới thiệu sau khi họ hoàn thành đăng ký tài khoản và xác minh số điện thoại thành công. Sau đó làm nhiệm vụ bạn sẽ nhận được tiền thưởng từ nhiệm vụ mà họ làm.
                            </Card.Text>
                            <Card.Text>
                                Nhận thêm 5% hoa hồng trọn đời từ doanh thu người bạn giới thiệu
                            </Card.Text>
                            <div className="mt-2">
                                <h6 className="font-weight-bolder">Liên kết giới thiệu</h6>
                                <div className="input-group mb-2">
                                    {user && (
                                        <input
                                            id="refLink"
                                            readOnly
                                            type="text"
                                            className="form-control"

                                            value={`https://moneytask.top/referral/${user.referralCode}`}
                                        />
                                    )}
                                    <Button className="btn btn-primary" onClick={handleCopy} disabled={isLoading} > {isLoading ? 'Đã sao chép' : 'Sao chép'} </Button>
                                </div>
                            </div>
                            <div className="alert alert-danger mb-0"> Giới thiệu gian lận, giới thiệu giả mạo, tự giới thiệu sẽ không được thanh toán và có thể bị khóa tài khoản </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Danh sách giới thiệu</Card.Title>
                            <Table striped variant="light">
                                <thead>
                                    <tr>
                                        <th>TÊN</th>
                                        <th>EMAIL</th>
                                        <th>SDT</th>
                                        <th>NGÀY CẬP NHẬT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user?.referredUsers?.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone || ''}</td>
                                            <td>{new Date(user.updatedAt).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default GioiThieu;