import '../App.css';
import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import { Navbar, Container, Button, Offcanvas, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import { useData } from '../GetData';


function Member() {
  const { user } = useData();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logOut = async () => {
    axios.get('/logout', { withCredentials: true });
  }

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Load user preference from localStorage
    const userPrefersDark = localStorage.getItem('darkMode') === 'true';
    setDarkMode(userPrefersDark);
    document.body.setAttribute('data-bs-theme', userPrefersDark ? 'dark' : 'light');
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.body.setAttribute('data-bs-theme', newDarkMode ? 'dark' : 'light');
    localStorage.setItem('darkMode', newDarkMode.toString());
  };

  return (
    <>
      <Container>
        <Navbar expand={false} className="mb-3 mt-3 border rounded border-dark-subtle">
          <Container>
            <Button variant="outline-secondary" onClick={() => setShow(true)}>
              <b><i className="bi bi-list" /> Menu</b>
            </Button>
            <NavDropdown title={<img src={user?.avatar} width="30" height="30" className="d-inline-block align-top" alt="Logo" />} menuVariant="light" align={{ lg: 'end' }}>
              <NavDropdown.Item as={Link} to="/member/hoso"><span className='ms-1 d-sm-inline fw-bold text-dark'> Hồ sơ </span></NavDropdown.Item>
              <hr className='my-1' />
              <NavDropdown.Item as={Link} to="/logout"><span className='ms-1 d-sm-inline fw-bold text-dark'> Đăng xuất</span></NavDropdown.Item>
            </NavDropdown>
          </Container>
        </Navbar>
      </Container>

      <Offcanvas className="d-lg-flex d-sm-none offcanvas-sizing" tabIndex="-1" id="offcanvas" show={show} keyboard={true} onHide={handleClose} backdrop={true} scroll={true}>
        <Offcanvas.Header className="offcanvas-header overflow-x-visible" closeButton>
          <Link to="/member" className="d-flex align-items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/img/logo-nobg.png`} alt="logo" className='w-50 mx-auto d-block d-sm-flex pb-0' />
          </Link>
        </Offcanvas.Header>

        <hr className='w-100 d-block my-0' />

        <div className="offcanvas-body px-0 py-0">
          <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
            <li className="nav-item">
              <Link to="/member" className="nav-link text-truncate">
                <i className="fs-3 bi bi-house" /> <span className="ms-1 d-sm-inline">Tổng quan</span>
              </Link>
            </li>
            <li>
              <Link to="/member/nhiemvu" className="nav-link text-truncate">
                <i className="fs-3 bi bi-list-task" /> <span className="ms-1 d-sm-inline">Vượt link rút gọn</span>
              </Link>
            </li>
            <li>
              <Link to="/member/duatop" className="nav-link text-truncate">
                <i className="fs-3 bi bi-list-ol" /> <span className="ms-1 d-sm-inline">Đua top</span>
              </Link>
            </li>
            <li>
              <Link to="/member/gioithieu" className="nav-link text-truncate">
                <i className="fs-3 bi bi-people" /> <span className="ms-1 d-sm-inline">Giới thiệu</span>
              </Link>
            </li>
            <li>
              <Link to="/member/ruttien" className="nav-link text-truncate">
                <i className="fs-3 bi bi-bank" /> <span className="ms-1 d-sm-inline">Rút tiền</span>
              </Link>
            </li>
            <li>
              <Link to="/member/hoso" className="nav-link text-truncate">
                <i className="fs-3 bi bi-people" /> <span className="ms-1 d-sm-inline">Hồ sơ</span>
              </Link>
            </li>

            {user?.role === "admin" && (
              <>
                <hr className="w-100 d-block my-0" />
                <NavDropdown id="nav-dropdown-dark-example" title={<span className='ms-1 d-sm-inline'><i className='fs-4 bi bi-person-fill-gear' /> Quản lý </span>} menuVariant="dark">
                  <NavDropdown.Item as={Link} to="/admin"><i className='fs-4 bi bi-person-fill-gear' /> <span className='ms-1 d-sm-inline'> Admin tổng quát </span></NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/tasks"><i className='fs-4 bi bi-person-lines-fill' /> <span className='ms-1 d-sm-inline'> Quản lý nhiệm vụ</span></NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/accounts"><i className='fs-4 bi bi-person-fill-gear' /> <span className='ms-1 d-sm-inline'> Quản lý tài khoản</span></NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/balances"><i className='fs-4 bi bi-person-plus-fill' /> <span className='ms-1 d-sm-inline'> Quản lý số dư</span></NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/admin/tasksaccept"><i className='fs-4 bi bi-person-plus-fill' /> <span className='ms-1 d-sm-inline'> Duyệt nhiệm vụ</span></NavDropdown.Item>
                </NavDropdown>
              </>
            )}

          </ul>
        </div>
        <div className='d-inline ps-4 mt-0'>
          <h6>Chờ duyệt: {user?.cash.choDuyet} </h6>
          <h6>Khả dụng: {user?.cash.daDuyet} </h6>
        </div>

        <NavDropdown className="py-sm-4 mt-sm-auto ms-4 d-flex" title={
          <span className="d-inline mx-1"><img src={user?.avatar} alt="avatar" width="28" height="28" className="rounded-circle" /> {user?.name} </span>
        }
        >
          <NavDropdown.Item as={Link} to="hoso"><span className='dropdown-item'>Hồ sơ</span></NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item><button onClick={() => logOut()} type="button" className='dropdown-item'>Đăng xuất</button></NavDropdown.Item>
        </NavDropdown>

      </Offcanvas>
      <div className="container-fluid">
        <div className="row">
          <div className="col min-vh-100">
            <Button className="d-lg-none mb-3" variant="outline-primary" size="sm" type="button" onClick={handleShow} >
              <i className="fs-1 bi bi-list" />
            </Button>
            <br />
            <Outlet />
          </div>
        </div>
      </div>
      <Button
        variant="secondary"
        className="position-fixed bottom-0 end-0 m-3"
        onClick={toggleDarkMode}
      >
        {darkMode ? <i className="bi bi-sun-fill" /> : <i className="bi bi-moon-fill" />}
      </Button>
    </>
  );
}

export default Member;
