import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

function QuanLySoDu() {
    const [users, setUsers] = useState(null);
    const [showModal, setShowModal] = useState({});
    const [sortOption, setSortOption] = useState('all'); // State for sorting option
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const usersPerPage = 20; // Number of items per page

    const handleShow = (id) => setShowModal({ ...showModal, [id]: true });
    const handleClose = (id) => setShowModal({ ...showModal, [id]: false });

    useEffect(() => {
        axios.get('/api/am/payout', { withCredentials: true })
            .then((usersResponse) => {
                if (usersResponse.status === 200) {
                    setUsers(usersResponse.data);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handlePayoutMember = (event, userId, userEmail) => {
        event.preventDefault();
        const formData = {
            hanhDong: document.getElementById(`hanhDong_${userId}`).value,
            ghiChu: document.getElementById(`ghiChu_${userId}`).value,
        };
        axios.post('/api/am/payout', { formData, userId, userEmail }, { withCredentials: true })
            .then((res) => { if (res.status === 200) { window.location.reload() } })
    };

    const statusPayout = (statusCode) => {
        switch (statusCode) {
            case 0:
                return 'Chờ duyệt';
            case 1:
                return 'Đã duyệt';
            case 2:
                return 'Đã gửi tiền';
            case 3:
                return 'Giữ lại';
            default:
                return 'Không xác định';
        }
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const filterUsers = (users) => {
        if (!users) return [];
        if (sortOption === 'all') return users;

        return users.filter(user =>
            user.data.some(dataItem => dataItem.accept === Number.parseInt(sortOption))
        ).map(user => {
            return {
                ...user,
                data: user.data.filter(dataItem => dataItem.accept === Number.parseInt(sortOption))
            };
        });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredUsers = filterUsers(users);
    const sortedUsers = filteredUsers.sort((a, b) => a.email.localeCompare(b.email)); // Sort users by email
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    const paginatedUsers = sortedUsers.slice(startIndex, endIndex);

    let payoutIndex = startIndex;

    return (
        <div className="row mb-3 ms-lg-5 ps-lg-5">
            <div className="col-md-10 ms-lg-5 ps-lg-5">
                <div className="card bg-secondary">
                    <div className="card-header border-0 bg-label-danger mb-3">
                        <div className="card-title mb-0 d-flex justify-content-between">
                            <div>
                                <h4 className="mb-0">Quản lý số dư thành viên</h4>
                            </div>
                            <div>
                                <select onChange={handleSortChange} value={sortOption} className="form-select">
                                    <option value="all">Tất cả</option>
                                    <option value="0">Chờ duyệt</option>
                                    <option value="1">Đã duyệt</option>
                                    <option value="2">Đã gửi tiền</option>
                                    <option value="3">Giữ lại</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive mb-2" style={{ height: "375px" }}>
                                    <table className="table align-items-center table-striped text-sm">
                                        <thead>
                                            <tr>
                                                <th className="p-0 pe-2 text-truncate">#</th>
                                                <th className="p-0 pe-2 text-truncate text-start">Email</th>
                                                <th className="p-0 pe-2 text-truncate">Phương thức rút tiền</th>
                                                <th className="p-0 pe-2 text-truncate">Tài khoản rút tiền</th>
                                                <th className="p-0 pe-2 text-truncate">Tên tài khoản rút tiền</th>
                                                <th className="p-0 pe-2 text-truncate">Số tiền rút</th>
                                                <th className="p-0 pe-2 text-truncate">Thời gian đặt lệnh rút</th>
                                                <th className="p-0 pe-2 text-truncate">Tình trạng</th>
                                                <th className="p-0 pe-2 text-truncate">Hành động</th>
                                            </tr>
                                        </thead>
                                        {paginatedUsers && (paginatedUsers.map((user) => (
                                            user.data.map((dataItem) => (
                                                <tbody className='mt-lg-2' key={dataItem._id}>
                                                    <tr>
                                                        <td className="p-0 pe-2 text-truncate">{++payoutIndex}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{user.email}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{dataItem.payment}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{dataItem.account}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{dataItem.name || "Không có"}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{dataItem.cash}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{new Date(dataItem.cratedAt).toLocaleString()}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">{statusPayout(dataItem.accept)}</td>
                                                        <td className="p-0 pe-2 text-truncate text-center">
                                                            <Button className="btn btn-sm btn-primary" type="button" onClick={() => handleShow(dataItem._id)} id={dataItem._id}> Hành động</Button>
                                                        </td>
                                                    </tr>
                                                    <Modal className="modal fade" id={`rutTien_${dataItem._id}`} tabIndex="-1" show={showModal[dataItem._id] || false} onHide={() => handleClose(dataItem._id)} >
                                                        <Modal.Header className="modal-header">
                                                            <h1 className="modal-title fs-5" id={`rutTien_${dataItem._id}`}>Xử lý rút tiền cho thành viên {user.email}</h1>
                                                        </Modal.Header>
                                                        <Modal.Body className="modal-body">
                                                            <form onSubmit={(e) => handlePayoutMember(e, dataItem._id, user.email)}>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`hanhDong_${dataItem._id}`}>Xử lý yêu cầu rút tiền</label>
                                                                    <select id={`hanhDong_${dataItem._id}`} name="hanhDong" className='form-select'>
                                                                        <option value="0">Chờ duyệt</option>
                                                                        <option value="1">Đã duyệt</option>
                                                                        <option value="2">Đã gửi tiền</option>
                                                                        <option value="3">Giữ lại</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor={`ghiChu_${dataItem._id}`}>Nội dung ghi chú</label>
                                                                    <input type="text" className="form-control" id={`ghiChu_${dataItem._id}`} name="ghiChu" placeholder="Nội dung ghi chú gửi cho member" defaultValue={dataItem.note} />
                                                                </div>
                                                                <Button type="reset" className="btn btn-secondary" onClick={() => handleClose(dataItem._id)}>Đóng</Button>
                                                                <button type="submit" className="btn btn-primary ms-2">Xác nhận</button>
                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>
                                                </tbody>
                                            ))
                                        )))}
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        onClick={() => handlePageChange(1)}
                                        className="page-item"
                                        type="button"
                                        disabled={currentPage === 1}
                                    >
                                        {"<<"}
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        className="page-item"
                                        type="button"
                                        disabled={currentPage === 1}
                                    >
                                        {"<"}
                                    </button>
                                    <span className="mx-lg-2">
                                        Trang {currentPage} / {Math.ceil(filteredUsers.length / usersPerPage)}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        className="page-item"
                                        type="button"
                                        disabled={endIndex >= filteredUsers.length}
                                    >
                                        {">"}
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(Math.ceil(filteredUsers.length / usersPerPage))}
                                        className="page-item"
                                        type="button"
                                        disabled={endIndex >= filteredUsers.length}
                                    >
                                        {">>"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuanLySoDu;