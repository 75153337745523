import React, { useRef, useEffect, useState } from 'react';
import { useData } from '../GetData';
import Chart from 'chart.js/auto';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import ScriptLoader from '../useScripts';

function ThongBao() {
    const { user } = useData();

    // CHART DATA
    // eslint-disable-next-line no-unused-vars
    const [isChartDataReady, setChartDataReady] = useState(false);
    const tasksRef = useRef(null);
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        if (user?.history) {
            setHistoryData(user.history);
        }
    }, [user]);

    useEffect(() => {
        if (!historyData.length || !tasksRef.current) {
            console.log("History data is not available or canvas not mounted.");
            return;
        }

        const tasksChartRef = tasksRef.current.getContext("2d");
        const tasksChart = new Chart(tasksChartRef, {
            type: "line",
            data: {
                labels: Array.from({ length: historyData.length }, (_, i) => `Ngày ${i + 1}`),
                datasets: [{
                    label: 'Lượt làm nhiệm vụ',
                    data: historyData,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    tension: 0.2,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                        position: 'top'
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                    }
                }
            }
        });

        setChartDataReady(true);

        return () => tasksChart.destroy();
    }, [historyData]);
    // END CHART DATA 

    const [tasksLog, setTasksLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchTasksLog = async () => {
            try {
                const response = await axios.get('/api/member/tasks-log', { withCredentials: true });
                setTasksLog(response.data);
            } catch (error) {
                console.error('Error fetching tasks log:', error);
            }
        };

        fetchTasksLog();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const statusTasks = (statusCode) => {
        const statuses = {
            0: 'Chưa hoàn thành',
            1: 'Đã hoàn thành',
            2: 'Từ chối duyệt',
        };
        return statuses[statusCode] || 'Không xác định';
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showLocked, setShowLocked] = useState(false);
    const handleCloseLocked = () => setShowLocked(false);
    const handleShowLocked = () => setShowLocked(true);

    async function checkIPCountry() {
        try {
            const response = await axios.get("https://ipleak.net/json");
            const data = response.data;
            const provider = data.isp_name.toLowerCase();
            const blockedProviders = ["cloudflarenet", "cloudflare", "ovh", "ovh-telecom", "digitalocean", "digitalocean-asn", "serverstack-asn", "hetzner-as", "oracle", "sun-as", "oracle-asnblock-asn", "hetzner-cloud2-as", "hetzner-cloud3-as", "hetzner-cloud4-as"];
            const isBlockedProvider = blockedProviders.some(blockedProvider => provider.includes(blockedProvider));
            if (isBlockedProvider) {
                handleShow();
            }
        } catch (error) {
            console.error("Error fetching IP country data:", error);
        }
    }
    checkIPCountry();

    useEffect(() => {
        if (user === null || user === undefined) {
            // User data is not yet loaded
            setShowLocked(false);
        } else if (user.locked === true) {
            setShowLocked(true);
        } else {
            setShowLocked(false);
        }
    }, [user]);

    const logOut = async () => {
        axios.get('/logout', { withCredentials: true });
    }

    const totalPages = Math.ceil(tasksLog.length / itemsPerPage);
    const currentTasks = tasksLog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y overflow-x-hidden">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="row">
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 fw-bold text-uppercase"> Số dư </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {user && (<span> <i className="bi bi-cash-stack" /> {user.cash.daDuyet} </span>)}
                                            </h5>
                                        </div>
                                        <a href="/member/nhiemvu" className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                            <div className="badge p-2 bg-label-danger rounded">
                                                <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 fw-bold text-uppercase"> Tiền chờ duyệt </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {user && (<span> <i className="bi bi-cash-stack" /> {user.cash.choDuyet} </span>)}
                                            </h5>
                                        </div>
                                        <a href="/member/nhiemvu" className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                            <div className="badge p-2 bg-label-danger rounded">
                                                <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 fw-bold text-uppercase"> Tổng thu nhập </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {user && (<span> <i className="bi bi-cash-stack" /> {user.cash.daRut} </span>)}
                                            </h5>
                                        </div>
                                        <a href="/member/" className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                            <div className="badge p-2 bg-label-warning rounded">
                                                <i className="fas fa-flower" style={{ fontSize: "3em" }} />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-4">
                            <div className="card z-index-2 bg-tertiary h-100">
                                <div className="card-header pb-0 pt-3 bg-transparent">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-capitalize fw-bolder"><i className="bi bi-megaphone-fill" /> Quy định</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <h6>&gt;&gt; <span className='text-danger fw-bolder'> Quy định rút tiền</span></h6>
                                    <ul>
                                        <li>Hệ thống kiểm duyệt và thanh toán và thứ 2 và thứ 6 hàng tuần.</li>
                                        <li>Min rút là 10.000Đ về ví Momo và tài khoản ngân hàng</li>
                                        <li>Nếu bạn có 1 lệnh rút chưa thanh toán, và bạn tiếp tục rút thêm 1 lệnh mới thù sẽ được thanh toán 1 lần vào thứ 2 và thứ 6</li>
                                    </ul>
                                    <h6>&gt;&gt; <span className='text-danger fw-bolder'> Chính sách khoá tài khoản</span></h6>
                                    <ul>
                                        <li>Dùng thủ thuật để gian lận nhiệm vụ, cheat nhiệm vụ sẽ bị khoá tài khoản.</li>
                                        <li>Nghiêm cấm chơi nhiều tài khoản trên cùng 1 mạng, thiết bị.</li>
                                        <li>Nghiêm cấm bật tắt 4G liên tục và dùng VPN trong khi làm nhiệm vụ.</li>
                                        <li>Nếu phát hiện tài khoản giả mạo, gian lận. Chúng tôi có thể khoá tài khoản vĩnh viễn và không duyệt các lệnh rút tiền với những tài khoản vi phạm.</li>
                                    </ul>
                                    <h6>&gt;&gt; <span className='text-danger fw-bolder'> Hỗ trợ</span></h6>
                                    <ul>
                                        <li>Kênh thông báo: <a href="https://t.me/moneytask_channel">https://t.me/moneytask_channel</a></li>
                                        <li>Nhóm chat: <a href="https://t.me/moneytask_group">https://t.me/moneytask_group</a></li>
                                        <li>Support: <a href="https://t.me/moneytask_top">https://t.me/moneytask_top</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-4">
                            <ScriptLoader src="https://www.vipads.live/vn/c-2066-25.js"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-4">
                            <div className="card z-index-2 bg-tertiary h-100">
                                <div className="card-header pb-0 pt-3 bg-transparent">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-capitalize fw-bolder"><i className="bi bi-bar-chart-line-fill" /> Thống kê</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <canvas ref={tasksRef} />
                                </div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Thời gian</th>
                                            <th scope="col">Số tiền</th>
                                            <th scope="col">Tình trạng</th>
                                            <th scope="col">Chi tiết</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTasks.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((task, index) => (
                                            <tr key={index}>
                                                <th scope="row">{(currentPage - 1) * itemsPerPage + index + 1}</th>
                                                <td>{formatDate(task.createdAt)}</td>
                                                <td>{task.daDuyet === 0 ? task.choDuyet : task.daDuyet}</td>
                                                <td>{statusTasks(task.com)}</td>
                                                <td>{`${task.taskName}`}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="secondary">
                                        {'<'}
                                    </Button>
                                    <span className='mx-lg-3'>Trang {currentPage}/{totalPages}</span>
                                    <Button onClick={handleNextPage} disabled={currentPage === totalPages} variant="secondary">
                                        {'>'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-danger fw-bolder text-center'>Chú ý!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Bạn đang sử dụng VPN/Proxy/1.1.1.1</Modal.Body>
                        <Modal.Body>Vui lòng tắt VPN/Proxy để có thể tiếp tục truy cập trang web cũng như làm nhiệm vụ của chúng tôi</Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger lg" className="w-100 fw-bolder" onClick={handleClose}>
                                Đã hiểu
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showLocked} onHide={handleCloseLocked} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-danger fw-bolder text-center'>Chú ý!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Tài khoản của bạn đã bị khoá.</Modal.Body>
                        <Modal.Body>Vui lòng liên hệ với Admin để biết thêm chi tiết</Modal.Body>
                        <Modal.Body>Telegram: <a href="https://t.me/moneytask_top">https://t.me/moneytask_top</a></Modal.Body>
			<Modal.Footer>
                            <Button variant="danger lg" className="w-100 fw-bolder" onClick={() => logOut()}>
                                Đã hiểu
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div >
        </>
    );
}

export default ThongBao;
