function QuyDinh() {
    return (
        <>
            <div className="row ms-lg-5 me-lg-5">
                <div className="col-md-6">
                    <div className="card regulation1-1 mb-4 bg-secondary overflow-y-auto" style={{ height: "350px" }}>
                        <div className="card-body">
                            <div className="text-center">
                                <h3>
                                    Tài khoản
                                </h3>
                            </div>
                            <div>
                                <ul>
                                    <li> Mỗi người chỉ được sử dụng 1 tài khoản duy nhất. </li>
                                    <li> <span className="fw-bolder text-danger"> Trùng IP </span>: Vi phạm khi các tài khoản chơi trên cùng 1 mạng Wifi hoặc 4G. </li>
                                    <li> Trường hợp Trùng IP còn do bật tắt 4G liên tục và dùng VPN trong khi làm nhiệm vụ. </li>
                                    <li> <span className="fw-bolder text-danger"> Trùng thiết bị </span>: Vi phạm khi các tài khoản chơi trên cùng 1 thiết bị di động, máy tính, laptop..v..v... </li>
                                    <li> Chúng tôi không chấp nhận các tài khoản tạo giả mạo, gian lận. </li>
                                    <li> Nếu phát hiện tài khoản giả mạo, gian lận. Chúng tôi có thể khóa tài khoản vĩnh viễn. </li>
                                    <li> Chúng tôi sẽ không duyệt các lệnh rút tiền đối với những tài khoản vi phạm. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card regulation1-1 mb-4 bg-secondary overflow-y-auto" style={{ height: "350px" }}>
                        <div className="card-body">
                            <div className="text-center">
                                <h3>
                                    Quy định
                                </h3>
                            </div>
                            <div>
                                <ul>
                                    <li> <span className="fw-bolder"> NGIÊM CẤM </span> sử dụng <span className="fw-bolder text-danger">Proxy/VPN</span> dưới mọi hình thức. </li>
                                    <li> <span className="fw-bolder"> NGIÊM CẤM </span> <span className="fw-bolder text-danger">bật tắt 4G hoặc Wifi</span> để <span className="fw-bolder text-danger">thay đổi IP</span> khi làm nhiệm vụ. </li>
                                    <li> <span className="fw-bolder"> NGIÊM CẤM </span> sử dụng <span className="fw-bolder text-danger">trình duyệt ẩn danh</span> khi vượt link. </li>
                                    <li> Trình duyệt cần cho phép sử dụng Cookie, Javascript. </li>
                                    <li> Không sử dụng Ad Block và các phần mềm chặn quảng cáo. </li>
                                    <li> Nhiệm vụ sẽ được Reset và cập nhật hằng ngày sau 24:00' mỗi ngày. </li>
                                    <li> Tiền lúa nhận được khi hoàn thành nhiệm vụ sẽ cộng dồn vào ví của bạn. </li>
                                    <li> Số lần làm nhiệm vụ chỉ bị trừ khi các bạn đã hoàn thành nhiệm vụ và nhận được lúa. </li>
                                    <li> Đối với các nhiệm vụ chưa hoàn thành, thì số lần làm nhiệm vụ vẫn giữ nguyên. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default QuyDinh;
