import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function TongQuan() {

    const visitorRef = useRef(null);
    const adsRef = useRef(null);
    const doneRef = useRef(null);
    const memberRef = useRef(null);

    useEffect(() => {
        const visitorsChartRef = visitorRef.current.getContext("2d");
        const visitorsChart = new Chart(visitorsChartRef, {
            type: "line",
            data: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: 'Số lượng truy cập',
                        data: [22, 10, 5, 99, 20, 30, 45],
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                        position: 'top'
                    }
                }
            }
        });

        const adsChartRef = adsRef.current.getContext("2d");
        const adsChart = new Chart(adsChartRef, {
            type: "bar",
            data: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: 'Thu nhập',
                        data: [22, 10, 5, 99, 20, 30, 45],
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false, // Set to false to hide the legend
                        position: 'top'
                    }
                }
            }
        });
        
        const doneChartRef = doneRef.current.getContext("2d");
        const doneChart = new Chart(doneChartRef, {
            type: "line",
            data: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: 'Hoàn thành',
                        data: [432826, 948908, 543692, 112162, 345159, 167974, 186065],
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false, // Set to false to hide the legend
                        position: 'top'
                    }
                }
            }
        });
        
        const memberChartRef = memberRef.current.getContext("2d");
        const memberChart = new Chart(memberChartRef, {
            type: "line",
            data: {
                labels: ["January", "February", "March", "April", "May", "June"],
                datasets: [
                    {
                        label: 'Thành viên',
                        data: [440, 433, 645, 732, 20, 211, 293],
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false, // Set to false to hide the legend
                        position: 'top'
                    }
                }
            }
        });

        return () => {
            visitorsChart.destroy();
            adsChart.destroy();
            doneChart.destroy();
            memberChart.destroy();
        };
    }, []);

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y overflow-x-hidden">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="row">
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số tiền chờ duyệt </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> <i className="bi bi-cash-stack" /> 0 </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-danger rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng số tiền đã duyệt </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> <i className="bi bi-cash-stack" /> 0 </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-danger rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Số tiền chưa rút của thành viên </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> <i className="bi bi-cash-stack" /> 0</span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-warning rounded">
                                            <i className="fas fa-coins" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Số tiền đã rút của thành viên </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> <i className="bi bi-cash-stack" /> 0</span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-warning rounded">
                                            <i className="fas fa-coin" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="card">
                                <div className='card-header'>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-capitalize">Số Liệu Thống Kê Lượt truy cập</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body p-3'>
                                    <canvas ref={visitorRef} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h6 className='text-capitalize'>Tổng thu nhập từ hệ thống link</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                        <canvas ref={adsRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="card">
                                <div className='card-header'>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-capitalize">Số lượt hoàn thành nhiệm vụ</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body p-3'>
                                    <canvas ref={doneRef} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-4'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h6 className='text-capitalize'>Số lượt thành viên truy cập</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                        <canvas ref={memberRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Chưa nghĩ ra */}
                </div>
            </div>
        </>
    );
}

export default TongQuan;
