import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useData } from '../GetData';
import { Button, Modal } from 'react-bootstrap';
import ScriptLoader from '../useScripts';

function NhiemVu() {
    const { user, tasks } = useData();

    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const cloudflareFallbackURLs = ['https://one.one.one.one/cdn-cgi/trace', 'https://1.0.0.1/cdn-cgi/trace', 'https://cloudflare-dns.com/cdn-cgi/trace', 'https://cloudflare-eth.com/cdn-cgi/trace', 'https://cloudflare-ipfs.com/cdn-cgi/trace', 'https://workers.dev/cdn-cgi/trace', 'https://pages.dev/cdn-cgi/trace', 'https://cloudflare.tv/cdn-cgi/trace', 'https://icanhazip.com/cdn-cgi/trace']

    async function getUserIP() {
        const data = await fetchWithFallback(cloudflareFallbackURLs).then(res => res.text());
        const arr = data.trim().split('\n').map(e => e.split('='));
        const obj = Object.fromEntries(arr);
        return obj
    }

    async function fetchWithFallback(links, obj) {
        let response;
        for (const link of links) {
            try {
                response = await fetch(link, obj)
                if (response.ok)
                    return response
            } catch (e) { }
        }
        return response
    }

    useEffect(() => {
        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);

    const handleDoTask = async (taskId) => {
        setLoading(true);

        const userIp = await getUserIP();
        axios.post('/api/member/tasks', { taskId, userIp }, { withCredentials: true }) //FIXME
            .then((res) => {
                if (res.status === 200) {
                    const newWindow = window.open(res.data, '_blank'); // TODO NEED CHECKING IN SAFARI
                    if (!newWindow) {
                        setModalContent(res.data);
                        setShowModal(true);
                    }
                }
            });
    };

    const [taskViews, setTaskViews] = useState([]);
    const [totals, setTotals] = useState({ views: 0, totalViews: 0 });

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get('/api/member/tasks', { withCredentials: true });
                const tasks = response.data.tasks ? Object.entries(response.data.tasks).map(([id, task]) => ({ id, ...task })) : [];
                setTaskViews(tasks);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    useEffect(() => {
        const calculateTotals = () => {
            let views = 0;
            let totalViews = 0;

            // biome-ignore lint/complexity/noForEach: <explanation>
            taskViews.forEach(task => {
                views += task.views || 0;
            });
            // biome-ignore lint/complexity/noForEach: <explanation>
            tasks?.filter(task => task.enable).forEach(task => {
                totalViews += task.taskViews || 0;
            });

            setTotals({ views, totalViews });
        };

        calculateTotals();
    }, [taskViews, tasks]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showLocked, setShowLocked] = useState(false);
    const handleCloseLocked = () => setShowLocked(false);
    const handleShowLocked = () => setShowLocked(true);
    async function checkIPCountry() {
        try {
            const response = await axios.get("https://ipleak.net/json");
            const data = response.data;
            const provider = data.isp_name.toLowerCase();
            const blockedProviders = ["cloudflarenet", "cloudflare", "ovh", "ovh-telecom", "digitalocean", "digitalocean-asn", "serverstack-asn", "hetzner-as", "oracle", "sun-as", "oracle-asnblock-asn", "hetzner-cloud2-as", "hetzner-cloud3-as", "hetzner-cloud4-as"];
            const isBlockedProvider = blockedProviders.some(blockedProvider => provider.includes(blockedProvider));
            if (isBlockedProvider) {
                handleShow();
            }
        } catch (error) {
            console.error("Error fetching IP country data:", error);
        }
    }
    checkIPCountry();

    if (user?.locked === true) {
        setShowLocked(true);
    };

    const logOut = async () => {
        axios.get('/logout', { withCredentials: true });
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y overflow-x-hidden">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="row">
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Số nhiệm vụ có sẵn </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> {totals.totalViews - totals.views} </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-danger rounded">
                                            <i className="fas fa-list" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Số nhiệm vụ đã làm </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                <span> {totals.views} </span>
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-info rounded">
                                            <i className="fas fa-check" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6">
                            <div className="card mb-4 bg-secondary">
                                <div className="card-body p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 font-weight-bold text-uppercase"> Tổng thu nhập </p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {user && (<span> <i className="bi bi-cash-stack" /> {user.cash.daDuyet} </span>)}
                                            </h5>
                                        </div>
                                        <div className="badge p-2 bg-label-warning rounded">
                                            <i className="fas fa-money-bill-wave" style={{ fontSize: "3em" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="text-start">
                        <h5>Danh sách nhiệm vụ</h5>
                    </div>
                    <div className="row">
                        {tasks && taskViews && (tasks
                            .filter(task => task.enable)
                            .filter(task => {
                                const taskView = taskViews.find(view => view.id === task._id) || {};
                                return (task.taskViews - taskView.views) !== 0;
                            })
                            .sort((a, b) => b.taskPrice - a.taskPrice)
                            .map((task) => {
                                const taskView = taskViews.find(view => view.id === task._id) || {};
                                return (
                                    <div className="col-xl-3 col-sm-6" key={task._id}>
                                        <div className="mb-4">
                                            <div className="card bg-tertiary" >
                                                <div className="card-body p-3">
                                                    <div className="d-flex">
                                                        <div className="my-auto w-100">
                                                            <div className="d-flex justify-content-between w-100">
                                                                <h6 className="task-name">{task.taskName}</h6>
                                                                <Button className="btn btn-sm btn-primary mb-0" style={{ backgroundColor: "#57CAE4", color: "#C8ACD6", borderRadius: "10px" }} type="button" onClick={() => handleDoTask(task._id)} disabled={isLoading}> <span> {isLoading ? 'Đang tải...' : 'Thực hiện'} </span>
                                                                </Button>
                                                            </div>
                                                            <div className="avatar-group mt-3">
                                                                <div className="text-sm">{task.taskDesc}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="horizontal light" />
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="text-sm font-weight-bold mb-0 opacity-8"><i className="fas fa-gift" /> {task.taskPrice}đ </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-sm font-weight-bold mb-0 opacity-8"><i className="fas fa-hammer" /> {task.taskViews - taskView.views || task.taskViews}/{task.taskViews} Views</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <ScriptLoader src="https://www.vipads.live/vn/c-2067-25.js" />
                    </div>
                </div>
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Link nhiệm vụ</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Vui lòng bấm vào link bên dưới để thực hiện nhiệm vụ:</p>
                        <a href={modalContent} target="_blank" rel="noopener noreferrer">{modalContent}</a>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Đóng
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger fw-bolder text-center'>Chú ý!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Bạn đang sử dụng VPN/Proxy/1.1.1.1</Modal.Body>
                    <Modal.Body>Vui lòng tắt VPN/Proxy để có thể tiếp tục truy cập trang web cũng như làm nhiệm vụ của chúng tôi</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger lg" className="w-100 fw-bolder" onClick={handleClose}>
                            Đã hiểu
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showLocked} onHide={handleCloseLocked} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger fw-bolder text-center'>Chú ý!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Tài khoản của bạn đã bị khoá.</Modal.Body>
                    <Modal.Body>Vui lòng liên hệ với Admin để biết thêm chi tiết</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger lg" className="w-100 fw-bolder" onClick={() => logOut()}>
                            Đã hiểu
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default NhiemVu;
