import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

// Create Context
const DataContext = createContext();

// Export the hook for accessing the context
export const useData = () => useContext(DataContext);

// Component to provide context
export const DataProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [tasks, setTasks] = useState(null);
    const location = useLocation();

    const path = ['/member','/member/','/member/nhiemvu','/member/ruttien','/member/duatop','/member/hoso','/member/quydinh','/admin','/admin/','/admin/tasks','/admin/accounts','/admin/balances', '/admin/tasksaccept']

    useEffect(() => {
        if (path.includes(location.pathname)) {
        Promise.all([
            axios.get('/api/user', { withCredentials: true}),
            axios.get('/api/tasks', { withCredentials: true})
        ])
        .then(([userResponse, tasksResponse]) => {
            if (userResponse.status === 200) {
                setUser(userResponse.data);
            }
            if (tasksResponse.status === 200) {
                setTasks(tasksResponse.data);
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <DataContext.Provider value={{ user, setUser, tasks, setTasks }}>
            {children}
        </DataContext.Provider>
    );
};